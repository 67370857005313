import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./nav/Header"
import "./layout.css"
import Footer from "./nav/footer";
import {SHeightMenuFull} from "../gatsby-plugin-theme-ui-old";
import {Box} from "@chakra-ui/react";
import Seo from "./seo";

const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    const title_seo = `${title!=null?title:""}`
  const [isScroll,setIsScroll] = useState(false)

    const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const yValue = 10
    if(isScroll){
        if(currentScrollY<yValue){
            setIsScroll(false);
        }
    }else{
        if(currentScrollY>yValue){
            setIsScroll(true);
        }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScroll]);

  return (
    <Box style={{overflowX:'hidden'}}>
        <Seo title={title_seo} />
<Header siteTitle={data.site.siteMetadata.title} isScroll={isScroll} />
        <Box
            sx={{
                width:'100%',
                position:'relative',
            backgroundColor:'bg.500',
                pt: `${SHeightMenuFull}px`
        }}>
        <main style={{minHeight:'100vh',paddingBottom:'8px'}}>
            {children}
        </main>
            <Footer/>
      </Box>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout