




// http://imagemechanics.com.au/#!/pages/break-it-down

export const SHeightMenuFull = 70
export const SHeightMenuLow = 30


export const SColorText = {
  500:"#0B0B08" ,//
  300:"#232119", //
  100: "#827A5E",
  50: "#BAB5A0"
}
export const SColorBG = {
  900:"#D8D5C9",
  700:"#E9E8E1",
  500:"#F2F1ED", // rgb(242, 241, 237),
  300: "#FBFAF9",
  'rgb':{
    900:'216, 213, 201',
    700:'233, 232, 225',
    500:'242, 241, 237',
  }
}

export const SFontTitle = "'Playfair Display SC', serif"
export const SFontTitle2 = "'Odibee Sans', serif"
//export const SFontBody = "'EB Garamond', serif"
export const SHeightMenuTop = 40

export const SColorPrimary = {
    900 : '#004F7C',
    700 : '#0076BA',
    500 : '#009bf7',
    300 : '#35B5FF',
    100 : '#AFE2FF',
  50: '#EDF8FF'
}

export const SColorGrey = {
    900 : '#212121',
    700 : '#616161',
    500 : '#9e9e9e',
    300 : '#e0e0e0',
    200 : '#f4f4f4',
    100 : '#f5f5f5',
}
export const SColorGreen = {
    900 : '#212121',
    700 : '#616161',
    500 : '#97C1A9',
    300 : '#e0e0e0',
    200 : '#f4f4f4',
    100 : '#f5f5f5',
}
export const SColorRed = {
    900 : '#212121',
    700 : '#616161',
    500 : '#FFB7B1',
    300 : '#e0e0e0',
    200 : '#f4f4f4',
    100 : '#f5f5f5',
}
export const SColorSecondary ={
    900 : '#997815',
    700 : '#CFA21D',
    500 : '#e5bd44',
    300 : '#ECD07A',
    100 : '#F4E3AF',
  50: '#FBF6E5'
}

export default {
  "space": [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  sizes: {
    container: 1280,
    heightMenu:SHeightMenuTop,
  },
  breakpoints: ["768px", "1025px", "1280px"],
  "fonts": {
    "heading":SFontTitle,
  "heading2" : SFontTitle2,
    "body": "old"  },
  "fontSizes": [10,
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    96
  ],
  "fontWeights": {
    "body": 400,
    "heading": 600,
    "bold": 700
  },
  "lineHeights": {
    "body": 1.75,
    "heading": 1.25
  },
  "colors": {
    "text": SColorText[500],
    "text300": SColorText[300],
    "text100": SColorText[100],
    "text50": SColorText[50],
    "grey":SColorGrey[500],
    "grey300":SColorGrey[300],
    "bg": SColorBG[500],
    "bg300": SColorBG[300],
    "bg700": SColorBG[700],
    "bg900": SColorBG[900],
    "border":SColorText[100]
  },
  "styles": {
    "root": {
      "fontFamily": "body",
      "lineHeight": "body",
      "fontWeight": "body"
    },
    "h1": {
      "color": "text",
      "fontFamily": "heading",
      "lineHeight": "heading",
      "fontWeight": "heading",
      "fontSize": 5
    },
    "a": {
      "color": "primary"
    },
    "pre": {
      "fontFamily": "monospace",
      "overflowX": "auto",
      "code": {
        "color": "inherit"
      }
    },
    "code": {
      "fontFamily": "monospace",
      "fontSize": "inherit"
    },
    "table": {
      "width": "100%",
      "borderCollapse": "separate",
      "borderSpacing": 0
    },
    "th": {
      "textAlign": "left",
      "borderBottomStyle": "solid"
    },
    "td": {
      "textAlign": "left",
      "borderBottomStyle": "solid"
    },
    "img": {
      "maxWidth": "100%"
    },

  },
  cards: {
      item: {
        padding:2, borderRadius:4,margin:1,
        bg:'white',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        //boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        " .title":{fontWeight:'bold',textTransform:'uppercase'},
        " .content":{p:1,position:'relative'}
      },
    primary: {
      padding: 2,
      bg:'grey700',
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  buttons: {

    primary: {
      m: 1,
      p:2,
      letterSpacing: 1.5,
      fontWeight: 'bold',
      transition: '0.5s',
      fontFamily: 'body',
      textTransform: 'uppercase',
      cursor: 'pointer',
      color: 'white',
      bg: 'primary',
      '&:hover': {
        bg: 'primary300',
        color: 'text',
      },
      ' svg':{
           fontSize: '24px',
    border:`0`,
        color:'text',
        opacity:0.6,
        margin:'0 8px -4px 4px',
    padding: '3px 0px 0px 3px'
      },
      ':focus': {outline: 0}
    },
    "icon":{
      m:1,
      p:2,
      letterSpacing:1.5,
      fontWeight:'bold',
      transition:'0.5s',
      fontFamily: 'body',
      textTransform:'uppercase',
      cursor:'pointer',
      color: 'text',
      bg: 'grey100',
      '&:hover': {
        bg: 'grey300',
        color: 'text',
      },
      ' svg':{
           fontSize: '23px',
    border:'0',
    marginBottom: '-4px',
    padding: '0'
      },
      ':focus': {outline:0}
    },

    "iconLow":{
      m:0,
      p:1,
      letterSpacing:1.5,
      fontWeight:'bold',
      transition:'0.5s',
      fontFamily: 'body',
      textTransform:'uppercase',
      cursor:'pointer',
      color: 'text',
      bg: 'grey100',
      '&:hover': {
        bg: 'grey300',
        color: 'text',
      },
      ' svg':{
           fontSize: '23px',
    border:'0',
    marginBottom: '-4px',
    padding: '0'
      },
      ':focus': {outline:0}
    }

  },
  badges: {
    primary: {
      color: 'bg',
      bg: 'text100',
      fontSize:12,
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
    }
  }


}

